<template>
  <v-card
    tile
    flat
    max-height="100vh"
    v-if="message !== null && typeof message !== 'undefined'"
  >
    <v-container>
      <v-row>
        <v-col
          class="col-6"
        >
          <v-card-subtitle class="pb-0">
            {{ $t('created') }}: {{ timestampToDateString(message.created) }}
          </v-card-subtitle>
        </v-col>

        <v-col
          v-if="message.namespace !== 'printjob'"
          class="col-5 text-lg-right pr-2 mt-2"
        >
          <v-btn 
            class="pb-0"
            color="primary"
            :loading="isProcessingMessage"
            @click="resendMessage"
          >
            {{ $t('resendMessage') }}
          </v-btn>
        </v-col>
        <v-col
          class="text-right col-6 py-0 px-4"
          :color="stateColor"
        >
          <v-btn
            v-if="message.namespace === 'printjob' && message.state === 'delivered'"
            dark
            depressed
            color="grey"
            @click="reprintPrintJob"
          >
            {{ $t('reprint') }}
          </v-btn>
        </v-col>
        <v-col class="py-0 col-12">
          <v-card-title class="text-capitalize">
            <span>
              {{ formatNameSpace(message.namespace) }}
            </span>
            <v-chip
              dark
              class="ma-2"
              :color="stateColor"
            >
              {{ message.state }}
            </v-chip>
          </v-card-title>
          <v-card-subtitle>
            {{ formattedDestination }}
          </v-card-subtitle>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 col-12">
          <div>
            <v-card-title class="d-inline-block text-capitalize">
              {{ $t('content') }}:
            </v-card-title>
            <v-btn
              v-if="contentHTML"
              class="pa-0 d-inline-block"
              text
              @click="showContentRaw = !showContentRaw"
            >
              ({{ $t('showRawLC') }})
            </v-btn>
          </div>
          <div class="px-4 pb-4">
            <div
              v-if="showContentRaw || !contentHTML"
              class="pa-4 grey lighten-3"
            >
              {{ message.content }}
            </div>
            <iframe
              v-else
              :srcdoc="message.content"
              style="width: 100%; height: 660px;"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "MessengerMessageProfile",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showContentRaw: false,
    isProcessingMessage: false
  }),
  computed: {
    contentHTML() {
      return this.message.namespace === 'printjob' || this.message.namespace === 'email'
    },
    formattedDestination() {
      // Here we'd like to put the messenger method instance label and mac address of printer
      return this.message.destination + ' - ' + this.message.uuid
    },
    stateColor() {
      switch (this.message.state) {
        case 'delivered':
          return 'success'
        case 'pending':
          return 'light-blue'
        default:
          return 'primary'
      }
    }
  },
  methods: {
    resendMessage() {
      this.isProcessingMessage = true

      this.$store.dispatch('resendMessage', {uuid: this.message.uuid, namespace: this.message.namespace}).then(res => {
        if(res && res.state === 'delivered') {
          this.$store.commit('updateActionSuccess', {
						message: 'Success',
						subMessage: 'Successfully resent message!'
					})
        }
        else {
          this.$store.commit('updateActionError', {
            message: 'Error',
            subMessage: 'Error occured while trying to send message: ' + JSON.stringify(res.payload)
          })
        }
      }).catch(e => {
        this.$store.commit('updateActionError', {
					message: 'Error',
					subMessage: 'Unknown error occured while trying to send message.'
				})
      }).finally(() => {
        this.isProcessingMessage = false
      })
    },
    reprintPrintJob() {
      this.$store.dispatch('reprintPrintjob', this.message.uuid).then(res => {
        if(res === true) {
          this.message.state = 'pending'
          alert(this.$t('printjobHasBeenMarkedForPrinting')) //TODO: Make it use the global success window
        }
      })
    },
    formatNameSpace(namespace) {
      if(namespace.toLowerCase() === 'sms') {
        return this.$t('SMS')
      }
      return namespace
    },
    timestampToDateString(timestamp) {
      const dateObj = new Date(timestamp)
      const date = '0' + dateObj.getDate()
      const month = '0' + dateObj.getMonth() + 1
      const year = dateObj.getFullYear()
      return date.substring(date.length - 2) + '/' + month.substring(month.length - 2) + '/' + year
    }
  }
}
</script>

<style scoped>

</style>
